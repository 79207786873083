import algoliasearch from "algoliasearch/lite"
import React from "react"
import ArticleCard from "../components/articleCard"
import Layout from "../components/layout"
import Pagination from "../components/pagination"
import Seo from "../components/seo"
import { getGeoData } from "../components/zipCodeConverter"

const searchClient = algoliasearch(
  "YOIEDX2EMU",
  "7a48bf24ee2cf5a5262dce727fa85737"
)

const algoliaSearch = (keyword, page = 1) => {
  page -= 1
  const index = searchClient.initIndex("prod_reinventing")
  return index.search(keyword, { page: page, hitsPerPage: 10 })
}

const algoliaProviderSearch = (
  lat,
  lng,
  // city,
  // state_id,
  // national,
  page = 1
) => {
  page -= 1
  const index = searchClient.initIndex("prod_providers")
  let params = { page: page, hitsPerPage: 10 }
  if (lat && lng) {
    params = { ...params, aroundLatLng: `${lat}, ${lng}`, aroundRadius: 50000 }
  }
  return index.search("", params)
}

const Search = ({ location }) => {
  const [mode, setMode] = React.useState()
  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    switch (params.get("mode")) {
      case "default":
        setMode("default")
        break
      case "providers":
        setMode("providers")
        break
    }
  }, [location])

  const render = mode => {
    switch (mode) {
      case "default":
        return <ArticleResults location={location} />
      case "providers":
        return <ProviderResults location={location} />
      default:
        return ""
    }
  }
  return render(mode)
}

const ProviderResults = ({ location }) => {
  const [result, setResult] = React.useState()
  const [zipCode, setZipCode] = React.useState()
  const [page, setPage] = React.useState()
  const [geoData, setGeoData] = React.useState()

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    const zipCode = params.get("zip")
    const page = parseInt(page) || 1
    setZipCode(zipCode)
    setPage(page)
    getGeoData(zipCode).then(geoData => {
      setGeoData(geoData)
      if (geoData) {
        algoliaProviderSearch(geoData.lat, geoData.lng, page).then(res =>
          setResult(res)
        )
      } else {
        setResult({ nbHits: 0, hits: [] })
      }
    })
  }, [location])

  return (
    <Layout>
      <Seo title={`Providers ${geoData ? "in " + geoData.city : ""}`} />
      {result && (
        <section>
          <h2>
            {geoData && (
              <>
                {result.nbHits} results near{" "}
                <span>
                  {geoData.zip} {geoData.city}, {geoData.state_id}
                </span>
              </>
            )}
          </h2>
          <hr />
          {JSON.stringify(result.hits)}
        </section>
      )}
    </Layout>
  )
}

const ArticleResults = ({ location }) => {
  const [result, setResult] = React.useState()
  const [query, setQuery] = React.useState()
  const [page, setPage] = React.useState()

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    const query = params.get("q")
    const page = parseInt(page) || 1
    setQuery(query)
    setPage(page)

    if (query) {
      algoliaSearch(query, page).then(res => setResult(res))
    } else {
      setResult({ nbHits: 0, hits: [] })
    }
  }, [location])

  return (
    <Layout>
      <Seo title={`Search results: ${query || "-"}`} />
      {result && (
        <section>
          <h2>
            {result.nbHits} results for <span>"{query}"</span>
          </h2>
          <hr />
          <div className="row gtr-uniform gtr-200">
            {result.hits.map(res => (
              <ArticleCard key={res} article={res} dynamicImage={true} />
            ))}
          </div>
          {result.nbHits > 10 && (
            <div className="row aln-center">
              <Pagination
                {...{
                  url: `/search?q=${query}`,
                  totalCount: 120,
                  pageSize: 10,
                  siblingCount: 1,
                  currentPage: page,
                }}
              />
            </div>
          )}
        </section>
      )}
    </Layout>
  )
}

export default Search
