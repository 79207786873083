import axios from "axios"

const zipBuckets = [
  [601, 2139],
  [2140, 3885],
  [3886, 5251],
  [5252, 7030],
  [7031, 8738],
  [8740, 11738],
  [11739, 12860],
  [12861, 14035],
  [14036, 15260],
  [15290, 16345],
  [16346, 17754],
  [17756, 19112],
  [19113, 21043],
  [21044, 22923],
  [22931, 24348],
  [24350, 25703],
  [25704, 27539],
  [27540, 28605],
  [28606, 29724],
  [29726, 31003],
  [31005, 32533],
  [32534, 33850],
  [33851, 35674],
  [35677, 37167],
  [37171, 38548],
  [38549, 40020],
  [40022, 41824],
  [41825, 43548],
  [43549, 44849],
  [44850, 46111],
  [46112, 47431],
  [47432, 48463],
  [48464, 49644],
  [49645, 50544],
  [50545, 52349],
  [52351, 54114],
  [54115, 55115],
  [55116, 56263],
  [56264, 57428],
  [57429, 58740],
  [58741, 60130],
  [60131, 61420],
  [61421, 62459],
  [62460, 63544],
  [63545, 65035],
  [65037, 66552],
  [66554, 67865],
  [67867, 69030],
  [69032, 71259],
  [71260, 72519],
  [72520, 73855],
  [73857, 75241],
  [75243, 76569],
  [76570, 77954],
  [77957, 79046],
  [79051, 80623],
  [80624, 83215],
  [83217, 84724],
  [84725, 87047],
  [87048, 89834],
  [89835, 92358],
  [92359, 94305],
  [94306, 95669],
  [95670, 97232],
  [97233, 98374],
  [98375, 99671],
  [99672, 99929],
]

const getBucketFilename = zipCode => {
  let res = zipBuckets.filter(el => el[0] <= zipCode && el[1] >= zipCode)
  if (res.length > 0) {
    res = res[0]
    return `${res[0]}-${res[1]}.json`
  }
}

export const getGeoData = zipCode => {
  zipCode = parseInt(zipCode)
  const jsonFile = getBucketFilename(zipCode)
  if (!jsonFile) {
    return Promise.resolve(null)
  }
  return axios.get(`/zipcodes/${jsonFile}`).then(zipCodes => {
    const res = zipCodes.data.filter(elem => elem.zip === zipCode)
    if (res.length > 0) {
      return res[0]
    }
  })
}
