import { Link } from "gatsby"
import React from "react"
import MarkdownView from "react-showdown"
import Image from "./image"

const ArticleCard = ({ article, dynamicImage }) => (
  <div key={article.slug} className="featured-row col-4 col-12-medium">
    <Link to={`/${article.slug}`} className="image fit">
      {dynamicImage ? (
        <img src={article.cover.image.formats.small.url} alt="" />
      ) : (
        <Image image={article.cover.image} />
      )}
    </Link>
    <h3>{article.title}</h3>
    <MarkdownView
      markdown={`${article.cover.content.substring(
        0,
        250
      )}... [full article](/${article.slug})`}
    />
  </div>
)

ArticleCard.defaultProps = {
  dynamicImage: false,
}

export default ArticleCard
